<script setup>
import logo from "@/assets/infoservice-w.png";
import hat from "@/assets/christmas-hat-png-28.png";
import bells from "@/assets/christmas-bell-png-30817.png";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import CustomLoyaltyDialog from "./CustomLoyaltyDialog.vue";
import { Dialog, useQuasar } from "quasar";
import SnowflakesOverlay from "./SnowflakesOverlay.vue";

const $q = useQuasar();
const store = useUserStore();
const user = computed(() => store.user);
const unreadNotifications = computed(() => store.unreadNotifications);

const toggleNightMode = () => {
  store.setDarkMode(!store.darkMode);
  $q.dark.set(store.darkMode);
};

const router = useRouter();
if (!user.value) router.push({ path: "/login" });

const createLoyalty = () => {
  Dialog.create({
    component: CustomLoyaltyDialog,
    componentProps: {},
  });
};

const showChristmasAssets = computed(() => {
  const date = new Date();
  return date.getMonth() === 11 || (date.getMonth() === 0 && date.getDate() <= 6);
});

const drawer = ref(false);
const mini = ref(true);
</script>

<template>
  <SnowflakesOverlay v-if="showChristmasAssets" />
  <q-header elevated>
    <q-toolbar class="text-white main-navbar">
      <q-btn dense flat round icon="fas fa-bars" @click="drawer = !drawer" />

      <q-toolbar-title>
        <div class="relative h-[48px] place-content-center">
          <img :src="hat" class="max-h-[32px] absolute top-[-2px] left-[-12px] pointer-events-none drop-shadow" v-if="showChristmasAssets" />
          <img :src="logo" class="img-fluid" style="max-height: 19px" />
        </div>
      </q-toolbar-title>

      <div v-if="showChristmasAssets && false" class="flex flex-col relative place-content-center">
        <img :src="bells" class="max-w-[60px] pointer-events-none drop-shadow-lg lg:mr-4" />
      </div>

      <q-btn dense flat round :icon="store.darkMode ? 'fas fa-moon' : 'fas fa-sun'" @click="toggleNightMode" size="xs" class="mr-4" />

      <!-- return button for mobile only -->
      <q-btn
        v-if="$q.platform.is.mobile"
        dense
        round
        icon="fas fa-arrow-left"
        @click="$router.go(-1)"
        class="q-mr-sm"
      />

      <!-- button without shadow -->
      <q-btn round flat color="blue-9">
        <q-avatar color="blue-5">
          <span style="font-size: 15px; color: #fff">{{
            user.name
              .split(" ")
              .map((a) => a[0])
              .join("")
          }}</span>
          <q-badge color="red" floating v-if="unreadNotifications">{{
            unreadNotifications
          }}</q-badge>
        </q-avatar>

        <q-menu anchor="bottom right" self="top right">
          <!-- Mensagens -->
          <q-item clickable to="/tickets">
            <q-item-section avatar>
              <q-icon name="fas fa-envelope" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Mensagens</q-item-label>
              <q-item-label caption v-if="unreadNotifications"
                >{{ unreadNotifications }} novas</q-item-label
              >
            </q-item-section>
          </q-item>

          <!-- Definições -->
          <q-item clickable to="/utilizador">
            <q-item-section avatar>
              <q-icon name="fas fa-cog" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Definições</q-item-label>
            </q-item-section>
          </q-item>

          <!-- Terminar Sessão -->
          <q-item clickable to="/logout">
            <q-item-section avatar>
              <q-icon name="fas fa-right-from-bracket" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Terminar sessão</q-item-label>
            </q-item-section>
          </q-item>
        </q-menu>
      </q-btn>

      <!--<router-link to="/utilizador" class="name-link">{{ user.name }}</router-link>-->
    </q-toolbar>
  </q-header>
  <div class="navbar-drawer">
    <q-drawer
      v-model="drawer"
      show-if-above
      :mini="mini"
      bordered
      mini-to-overlay
      @mouseover="mini = false"
      @mouseout="mini = true"
    >
      <q-list>
        <q-item clickable to="/" exact>
          <q-item-section avatar>
            <q-icon name="fas fa-screwdriver-wrench" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Atualizar processo</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/agendamentos">
          <q-item-section avatar>
            <q-icon name="fas fa-calendar-days" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Agendamentos</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/dicas" v-if="$q.platform.is.mobile">
          <q-item-section avatar>
            <q-icon name="fas fa-info" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Dicas de reparação</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/acessos" v-if="$q.platform.is.mobile && !user?.external">
          <q-item-section avatar>
            <q-icon name="fas fa-globe" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Acessos</q-item-label>
          </q-item-section>
        </q-item>


        <q-item clickable to="/unscheduled" v-if="user && user.admin">
          <q-item-section avatar>
            <q-icon name="fas fa-map-location-dot" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Mapa de agendamentos</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/inventario" v-if="!user || !user.external">
          <q-item-section avatar>
            <q-icon name="fas fa-boxes-stacked" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Inventário</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/processos">
          <q-item-section avatar>
            <q-icon name="fas fa-list" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Lista de processos</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/admin/visits" v-if="user && !user.external">
          <q-item-section avatar>
            <q-icon name="fas fa-pen-to-square" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Atualizações</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/tickets">
          <q-item-section avatar>
            <q-icon name="fas fa-envelope" />
          </q-item-section>

          <q-item-section>
            <q-item-label>Mensagens</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/abastecimentos" v-if="user && (user.warehouse || user.admin)">
          <q-item-section avatar>
            <q-icon name="fas fa-truck-ramp-box" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Abastecimentos</q-item-label>
          </q-item-section>
        </q-item>

        <q-expansion-item
          expand-separator
          icon="fas fa-toolbox"
          label="Ferramentas"
        >
          <q-item clickable to="/dicas" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-info" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Dicas de reparação</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            to="/actividade"
            v-if="!user || !user.external"
            :inset-level="0.5"
          >
            <q-item-section avatar>
              <q-icon name="fas fa-timeline" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Actividade</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/search" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-magnifying-glass" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pesquisar processos</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            to="/acessos"
            v-if="!user || !user.external"
            :inset-level="0.5"
          >
            <q-item-section avatar>
              <q-icon name="fas fa-globe" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Acessos</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            to="/toolbox"
            v-if="!user || !user.external"
            :inset-level="0.5"
          >
            <q-item-section avatar>
              <q-icon name="fas fa-toolbox" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Outras</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            clickable
            @click="createLoyalty"
            :inset-level="0.5"
            v-if="user"
          >
            <q-item-section avatar>
              <q-icon name="fas fa-star" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Submeter loyalty</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          icon="fas fa-lock"
          label="Administração"
          v-if="user && user.admin"
        >
          <q-item clickable to="/admin" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-cog" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Admin</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/admin/utilizadores" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-users" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Utilizadores</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/admin/locations" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-location-dot" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Localizações</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/admin/bot-tickets" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-robot" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Mensagens da Sheila</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/mensagens-worten" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-envelope" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Mensagens na Worten</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/admin/sms" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-message" />
            </q-item-section>
            <q-item-section>
              <q-item-label>SMS Samsung</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable to="/loyalties" :inset-level="0.5">
            <q-item-section avatar>
              <q-icon name="fas fa-star" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Loyalty</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-item
          clickable
          href="https://picaponto.pt/ponto/714/Beiratronica-Lda"
          target="_blank"
          v-if="user && !user.external"
        >
          <q-item-section avatar>
            <q-icon name="fas fa-clock" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Picaponto</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/logout">
          <q-item-section avatar>
            <q-icon name="fa fa-right-from-bracket" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Terminar sessão</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
  </div>
</template>

<style scoped>
@media print {
  .main-navbar {
    display: none;
  }

  .navbar-drawer {
    display: none !important;
  }
}

.navbar-brand img {
  max-width: 220px;
}



.name-link {
  color: inherit;
  text-decoration: inherit;
}

.main-navbar {
  background-color: var(--q-primary);
  z-index: 2001;
}

body.body--dark .main-navbar {
  background-color: #045194;
}
</style>

<style>
body.body--dark .q-layout__shadow:after {
  box-shadow: none;
}
</style>

<style>
@media print 
{
  @page { margin: 0; }
  body  { margin: 1.6cm; }
  body { margin-left: -1cm; margin-top: -1cm; }
  .no-print { display: none !important; }
}

</style>